import React from "react";

const Advantages = () => {
    const Item = ({color, secondColor, textColor, secondTextColor, children, index}) => <div className={`advantages-item ${color}`}>
        <div className={`advantages-item-index ${secondColor} ${secondTextColor} effects-white-shadow-mini`}>{index}</div>
        <p className={`${textColor}`}>{children}</p>
    </div>;

    return <div className="advantages">
        <Item index={1} color="bg-black" textColor="text-white" secondColor="bg-yellow-400" secondTextColor="text-black">
            Среди наших клиентов — крупнейшие строительные компании
        </Item>
        <Item index={2} color="bg-yellow-400" textColor="text-black" secondColor="bg-black" secondTextColor="text-white">
            Вы сможете приобрести у нас надежную технику по самым низким ценам
        </Item>
        <Item index={3} color="bg-amber-500" textColor="text-black" secondColor="bg-white" secondTextColor="text-black">
            У нас Вы сможете получить  качественное сервисное сопровождение
        </Item>
    </div>
};

export default Advantages;