import React, {useState} from "react";
import PhoneIcon from "../../img/icons/phone.png";
import EmailIcon from "../../img/icons/email.png";

const Service = () => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [comment, setComment] = useState("");
    const [allowPolicy, setAllowPolicy] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const send = e => {
        e.preventDefault();
        setIsLoading(true);

        fetch(`https://gkbig.bitrix24.ru/rest/290/jsg2ep5s0uat2e3s/crm.deal.add.json?FIELDS[TITLE]=${encodeURI(`Заявка с сайта BULL - "Оставить заявку на ремонт" - ${phone}`)}&FIELDS[COMMENTS]=${encodeURI(`Имя: ${name}; Телефон: ${phone}${comment ? `; Модель погрузчика: ${comment}` : ""}`)}`).then(() => {
            setIsLoading(false);
            setName("");
            setPhone("");
            setComment("");
            setAllowPolicy(false);
        });
    };

    return <div className="service" id="service">
        <div className="service-up">
            <p className="title">
                СЕРВИС
            </p>
            <p className="subtitle">
                ООО «Большой-Техника» является не только официальным представителем спецтехники BULL в России, но и
                надежным поставщиком запасных частей и расходных материалов для неё.
            </p>
            <p className="text">
                Более 150 наименований качественных оригинальных запасных частей для экскаваторов-погрузчиков BULL в
                наличии на нашем складе. Если вы не нашли нужную запасную часть в каталоге, мы закажем и доставим её в
                кратчайшие сроки.
            </p>
        </div>

        <div className="service-down">
            <div className="service-down-content">
                <p className="title">
                    Сервисное гарантийное и послегарантийное <span className="title-yellow">обслуживание спецтехники BULL</span>
                </p>
                <div className="links">
                    <a href="tel:88006004136"><img src={PhoneIcon} alt=""/> 8(800) 600-41-36</a>
                    <a href="mailto:zvv@big-teh.ru"><img src={EmailIcon} alt=""/> zvv@big-teh.ru</a>
                </div>
            </div>
            <div className="service-down-form">
                <div className="border">
                    <form onSubmit={send}>
                        <p className="title">
                            ОСТАВЬ ЗАЯВКУ <br/> <span className="title-orange">
                            НА РЕМОНТ
                        </span>
                        </p>
                        <p className="text">Оставить заявку на премонт техники BULL</p>
                        <input type="text" placeholder="Имя" onInput={e => setName(e.target.value)} value={name}/>
                        <input type="text" placeholder="Телефон" onInput={e => setPhone(e.target.value)} value={phone}/>
                        <input type="text" placeholder="Модель погрузчика" onInput={e => setComment(e.target.value)}
                               value={comment}/>
                        <button
                            disabled={isLoading || (!phone || !name || !comment || !allowPolicy)}>{isLoading ? "Отправка..." : "Отправить"}</button>
                        <label className="control control-checkbox">
                            Я даю согласие на обработку персональных данных и подтверждаю, что мне есть 18 лет
                            <input type="checkbox" checked={allowPolicy} onChange={() => setAllowPolicy(!allowPolicy)}/>
                            <div className="control_indicator"></div>
                        </label>
                    </form>
                </div>
            </div>
        </div>

        <div className="service-down-desktop">
            <div className="service-down-desktop-content">
                <p className="title">
                    Сервисное гарантийное и послегарантийное <span className="title-yellow">обслуживание спецтехники BULL</span>
                </p>
                <div className="links">
                    <a href="tel:88006004136"><img src={PhoneIcon} alt=""/> 8(800) 600-41-36</a>
                    <a href="mailto:zvv@big-teh.ru"><img src={EmailIcon} alt=""/> zvv@big-teh.ru</a>
                </div>
            </div>
            <div className="service-down-desktop-form">
                <form onSubmit={send}>
                    <p className="title">
                        ОСТАВЬ ЗАЯВКУ <br/> <span className="title-orange">
                            НА РЕМОНТ
                        </span>
                    </p>
                    <p className="text">Оставить заявку на премонт техники BULL</p>
                    <input type="text" placeholder="Имя" onInput={e => setName(e.target.value)} value={name}/>
                    <input type="text" placeholder="Телефон" onInput={e => setPhone(e.target.value)} value={phone}/>
                    <input type="text" placeholder="Модель погрузчика" onInput={e => setComment(e.target.value)}
                           value={comment}/>
                    <button
                        disabled={isLoading || (!phone || !name || !comment || !allowPolicy)}>{isLoading ? "Отправка..." : "Отправить"}</button>
                    <label className="control control-checkbox">
                        Я даю согласие на обработку персональных данных и подтверждаю, что мне есть 18 лет
                        <input type="checkbox" checked={allowPolicy} onChange={() => setAllowPolicy(!allowPolicy)}/>
                        <div className="control_indicator"></div>
                    </label>
                </form>
            </div>
        </div>
    </div>
};

export default Service;