import React, {useEffect, useState} from "react";
import CartWhiteIcon from "../../img/icons/cartWhite.png";
import {ReactSVG} from "react-svg";
import CloseIcon from "../../img/icons/close.svg";
import {useDispatch} from "react-redux";
import {openModal} from "../../store/appSlice";

const Modal = () => {
    const [isShow, setIsShow] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => setIsShow(true), 30000);
    }, []);

    return <>
        <div
            className={`w-screen h-screen fixed bg-opacity-50 bg-black z-50 top-0 left-0 ${isShow ? "flex" : "hidden"} justify-center items-center`}>
            <div className="fixed top-10 right-10" onClick={() => setIsShow(false)}>
                <ReactSVG src={CloseIcon}/>
            </div>
            <div
                className="w-[95%] h-max lg:h-[70%] bg-gradient-to-r from-[#fff] to-[#FFF300] flex flex-col lg:flex-row p-5 lg:p-10 justify-between">
                <div className="w-[40%] hidden lg:block">
                    <div className="text-black text-5xl font-black">
                        Колесный экскаватор BULL EW180
                    </div>
                    <div className="text-black text-lg font-medium mt-5">
                        Гидравлический насос Kawasaki, клапанный блок Parker, гидромотор привода поворота платформы
                        Doosan, мосты ZF, дополнительная гидролиния под навесное оборудование, гидравлический
                        быстросъёмный механизм, централизованная система смазки
                    </div>
                    <ul className="list mt-5 font-bold">
                        <li>Объём ковша, м3 - 1.0</li>
                        <li>Грузоподъёмность, кг - 17.100</li>
                        <li>Мощность двигателя, л.с -205 л.с.</li>
                    </ul>
                    <button
                        className="pl-6 pr-7 py-2 bg-black rounded-3xl justify-start items-center gap-3 inline-flex mt-5"
                        onClick={() => dispatch(openModal({item: "Колесный экскаватор BULL EW180", type: "item"}))}
                    >
                        <div className="w-6 h-6 relative">
                            <img className="w-6 h-6 left-0 top-0 absolute" src={CartWhiteIcon}/>
                        </div>
                        <div className="text-white text-2xl font-semibold">Заказать</div>
                    </button>
                </div>
                <div className="w-[50%] hidden lg:flex flex-col justify-between">
                    <div className="flex justify-between">
                        <p>ООО «Большой-Техника»</p>
                        <a href="mailto:zw@big-teh.ru">zw@big-teh.ru</a>
                    </div>
                    <div className="text-right text-black text-5xl font-bold mt-5">
                        НИЗКАЯ ЦЕНА
                        <br/>
                        ВЫСОКОЕ КАЧЕСТВО
                    </div>
                    <img src={require("../../img/items/EW180/main.png")} alt=""/>
                    <div className="flex justify-between">
                        <p></p>
                        <a href="tel:88006004136">8(800) 600-41-36</a>
                    </div>
                </div>
                <div className="flex lg:hidden flex-col w-full">
                    <div className="flex justify-between text-[14px]">
                        <p>ООО «Большой-Техника»</p>
                        <a href="mailto:zw@big-teh.ru">zw@big-teh.ru</a>
                    </div>
                    <div className="text-black text-lg font-black w-[70%] leading-5 mt-5">
                        Колесный экскаватор BULL EW180
                    </div>
                    <div
                        className="text-black text-xs font-normal mt-2.5 leading-3">
                        Гидравлический насос Kawasaki, клапанный блок Parker, гидромотор привода поворота платформы
                        Doosan, мосты ZF, дополнительная гидролиния под навесное оборудование, гидравлический
                        быстросъёмный механизм, централизованная система смазки
                    </div>
                    <img src={require("../../img/items/EW180/main.png")} alt=""/>
                    <ul className="list mt-5 font-bold">
                        <li>Объём ковша, м3 - 1.0</li>
                        <li>Грузоподъёмность, кг - 17.100</li>
                        <li>Мощность двигателя, л.с -205 л.с.</li>
                    </ul>
                    <button
                        className="pl-6 pr-7 py-2 w-max bg-black rounded-3xl justify-start items-center gap-3 inline-flex mt-5"
                        onClick={() => dispatch(openModal({item: "Колесный экскаватор BULL EW180", type: "item"}))}
                    >
                        <div className="w-5 h-5 relative">
                            <img className="w-5 h-5 left-0 top-0 absolute" src={CartWhiteIcon}/>
                        </div>
                        <div className="text-white text-xl font-semibold">Заказать</div>
                    </button>
                    <div className="flex justify-between mt-5">
                        <div className="w-36 h-9 text-black text-xs font-bold font-['Montserrat'] leading-3">НИЗКАЯ
                            ЦЕНА <br/>ВЫСОКОЕ КАЧЕСТВО
                        </div>
                        <a href="tel:88006004136" className="text-[14px]">8(800) 600-41-36</a>
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default Modal;