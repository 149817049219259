import React from "react";
import MapImage from "../../img/Map.png";

const Map = () => {
    return <div className="map">
        <p className="title">
            ОФИЦИАЛЬНЫЙ ДИЛЕР
            СПЕЦТЕХНИКИ BULL <span className="title-orange">С ЛУЧШИМ СООТНОШЕНИЕМ ЦЕНЫ И КАЧЕСТВА!</span>
        </p>
        <p className="subtitle">
            ООО «БУЛЛ РАША»является официальным представителем компании BULL Machines в России. Китайская спецтехника
            BULL, уже завоевавшая доверие на мировом рынке, стремительно набирает обороты по использованию на территории
            РФ
        </p>
        <img src={MapImage} alt=""/>
        <p className="text">
            Компания «Большой-Техника» предлагает специальные условия для дальнейшего сотрудничества с заинтересованными
            организациями и оптовиками. Подробнее о предложениях вы можете узнать по телефону <a className="font-bold" href="tel:88006004136">8(800) 600-41-36</a>
        </p>
    </div>
};

export default Map;