import React from "react";
import PlayIcon from "../../img/icons/play.png";
import FirstBlockImg from "../../img/FirstBlock.png";
import {useDispatch} from "react-redux";
import {openModal} from "../../store/appSlice";

const FirstBlock = () => {
    const dispatch = useDispatch();

    return <div className="first-block" id="first-block">
        <div className="first-block-content">
            <h2>
                С нашей техникой Вы достигните новых высот
            </h2>
            <p>
                Сделав выбор в пользу нашей компании, Вы сможете купить спецтехнику и запчасти по выгодным ценам!
            </p>
            <div className="first-block-content-buttons">
                <button className="btn btn-black btn-big effects-white-shadow" onClick={() => dispatch(openModal({item: "Оставить запрос", type: ""}))}>Оставить запрос</button>
                <div
                    className="btn btn-icon btn-black">
                    <img src={PlayIcon} alt="" className="w-8 h-8"/>
                </div>
            </div>
        </div>
        <div className="first-block-image">
            <img src={FirstBlockImg} alt="" className="w-max h-full"/>
        </div>
    </div>
};

export default FirstBlock;