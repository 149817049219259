import React, {useCallback, useEffect, useState} from "react";
import LogoImg from "../../img/Logo.png";
import MenuIcon from "../../img/icons/menu.svg";
import CloseIcon from "../../img/icons/close.svg";
import PhoneIcon from "../../img/icons/phone.png";
import format from "../../helpers/format";
import {Link} from "react-router-dom";

const NavHeader = ({children}) => {
    return <div className="nav">
        {children}
    </div>
};

const NavHeaderLink = ({children, to = "#", type = "react", target = ""}) => type === "react"
    ? <Link to={to} className="nav-item">{children}</Link>
    : <a href={to} target={target} className="nav-item">{children}</a>;

const HeaderPhoneLink = ({phone}) => {
    return <a href={`tel:${format.phoneLink(phone)}`}
              className="nav-item"><img
        src={PhoneIcon} alt=""/> {phone}</a>
};

const HeaderMenuTitle = ({children}) => {
    return <p className="header-menu-title">{children}</p>
};

const HeaderMenuLink = ({children, to = "#", type = "react", target = ""}) => type === "react"
    ? <Link to={to} className="header-menu-item">{children}</Link>
    : <a href={to} target={target} className="header-menu-item">{children}</a>;

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleClickNavLink = useCallback(() => {
        setIsMenuOpen(false);
    } ,[]);

    useEffect(() => {
        document.querySelectorAll(".header-menu a").forEach(link => link.addEventListener("click", handleClickNavLink));
        return () => {
            document.querySelectorAll(".header-menu a").forEach(link => link.removeEventListener("click", handleClickNavLink));
        };
    }, [handleClickNavLink]);

    return <>
        <header className="header">
            <div className="header-desktop">
                <img src={LogoImg} alt="" className="logo"/>
                <NavHeader>
                    <NavHeaderLink type="react" to="/#first-block">Главная</NavHeaderLink>
                    <NavHeaderLink type="react" to="/#about">О нас</NavHeaderLink>
                    <NavHeaderLink type="react" to="/#catalog">Каталог</NavHeaderLink>
                    <NavHeaderLink type="react" to="/#service">Сервис</NavHeaderLink>
                    <NavHeaderLink type="react" to="/#contacts">Контакты</NavHeaderLink>
                </NavHeader>
            </div>
            <div className="header-desktop">
                <HeaderPhoneLink phone="8(800) 600-41-36"/>
            </div>

            <div
                className={`header-mobile ${isMenuOpen ? `opacity-0` : `opacity-100`}`}>
                <img src={MenuIcon} alt="" onClick={() => setIsMenuOpen(true)}/>
            </div>
            <div className="header-mobile">
                <img src={LogoImg} alt="" className="logo"/>
            </div>
            <div
                className={`header-mobile ${isMenuOpen ? `opacity-100` : `opacity-0`}`}>
                <img src={CloseIcon} alt="" onClick={() => setIsMenuOpen(false)}/>
            </div>
        </header>
        <div className={`header-menu ${isMenuOpen ? `open` : ""}`}>
            <HeaderMenuTitle>Быстрое меню</HeaderMenuTitle>
            <HeaderMenuLink type="react" to="/#first-block">Главная</HeaderMenuLink>
            <HeaderMenuLink type="react" to="/#about">О нас</HeaderMenuLink>
            <HeaderMenuLink type="react" to="/#catalog">Каталог</HeaderMenuLink>
            <HeaderMenuLink type="react" to="/#service">Сервис</HeaderMenuLink>
            <HeaderMenuLink type="react" to="/#contacts">Контакты</HeaderMenuLink>
            <HeaderMenuTitle>Каталог</HeaderMenuTitle>
            <HeaderMenuLink>SL220 (4 в 1)</HeaderMenuLink>
            <HeaderMenuLink>SL220</HeaderMenuLink>
            <HeaderMenuLink>SL300</HeaderMenuLink>
            <HeaderMenuLink>3SX</HeaderMenuLink>
            <HeaderMenuLink>4SX</HeaderMenuLink>
            <HeaderMenuLink>SL1050</HeaderMenuLink>
            <HeaderMenuLink>SL700</HeaderMenuLink>
            <HeaderMenuLink>SLC1500</HeaderMenuLink>
            <HeaderMenuLink>EW210</HeaderMenuLink>
            <HeaderMenuLink>EW180</HeaderMenuLink>
            <div className="header-menu-footer">
                <p className="title">ООО "Большой-Техника"</p>
                <p className="subtitle">ОФИЦИАЛЬНЫЙ ДИЛЕР BULL НА ТЕРРИТОРИИ РОССИИ</p>
                <div className="links">
                    <a href="tel:88006004136">8(800) 600-41-36</a>
                    <a href="mailto:zw@big-teh.ru">zw@big-teh.ru</a>
                </div>
            </div>
        </div>
    </>
};

export default Header;