import React from "react";
import QuestionImage from "../../img/Question.png";
import {useDispatch} from "react-redux";
import {openModal} from "../../store/appSlice";

const Question = () => {
    const dispatch = useDispatch();

    return <div className="bg-gradient-to-br from-[#FEA200] to-[#FFC700] flex flex-col lg:flex-row justify-center items-start lg:items-center p-5">
        <div className="question-image">
            <img src={QuestionImage} alt=""/>
        </div>
        <div className="w-px h-32 border border-black hidden lg:block mx-10"/>
        <div className="question-content">
            <p className="text-xs lg:text-xl font-medium">Подробно расскажем о наших услугах, видах работ и типовых проектах, рассчитаем
                стоимость и подготовим индивидуальное предложение!</p>
        </div>
        <div className="w-20 h-px border border-black block lg:hidden my-5"/>
        <div className="question-buttons">
            <button className="px-2.5 py-2 lg:px-4 lg:py-3.5 bg-white rounded-xl shadow justify-center items-center gap-3 inline-flex text-sm lg:text-xl font-bold effects-white-shadow" onClick={() => dispatch(openModal({item: "Задать вопрос", type: "q"}))}>
                Задать вопрос
            </button>
        </div>
    </div>
};

export default Question;