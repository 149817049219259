import React from "react";
import About0Img from "../../img/About0.png";
import About1Img from "../../img/About1.png";
import RubleIcon from "../../img/icons/ruble.png";

const About = () => {
    return <div className="about" id="about">
        <div className="about-image">
            <div className="about-image-content">
                <img src={About1Img} alt=""/>
                <img src={About0Img} alt=""/>
                <span/>
            </div>
        </div>
        <div className="about-text">
            <div className="title">О НАС</div>
            <div className="subtitle">В настоящий момент в
                ООО «Большой-Техника» под брендом BULL можно приобрести различную дорожно-строительную технику по
                максимально выгодной цене
            </div>
            <div className="text">Особенностью бренда BULL
                является исключительное соотношение цены и качества в сочетании с удачными техническими решениями и
                надёжностью. Мы предлагаем наличие широкого ассортимента техники, созданной специально под запросы
                российского клиента. Эксклюзивный дистрибьютором BULL, компания «Большой-Техника» имеет развитую
                филиальную сеть и оказывает профессиональную сервисную поддержку всей приобретённой техники BULL.⁠
            </div>
            <div className="icon-block">
                <div className="icon">
                    <img src={RubleIcon} alt=""/>
                </div>
                <div className="text">
                    Цена может быть очень важным фактором при выборе экскаватора. Некоторые производители могут
                    предлагать экскаваторы по более доступным ценам, чем другие, без потери качества.
                </div>
            </div>
            <ul>
                <li>экскаваторы-погрузчики</li>
                <li>фронтальные погрузчики</li>
                <li>мини-погрузчики</li>
                <li>колесные экскаваторы</li>
            </ul>
        </div>
    </div>
};

export default About;