import React, {useState} from "react";
import BlackLogoImage from "../../img/BlackLogo.png";
import CartIcon from "../../img/icons/cart.png";
import {db} from "../../db";
import {useDispatch} from "react-redux";
import {openModal} from "../../store/appSlice";

const BigCatalog = () => {
    const [items, setItems] = useState(db.items);
    const dispatch = useDispatch();

    const Item = ({name, desc, list, mainImage, images}) => {
        return <div className="flex p-5 lg:p-10 bg-white rounded-2xl lg:rounded-none">
            <div className="hidden lg:flex flex-col w-2/4">
                <div className="flex items-center gap-2">
                    <img src={BlackLogoImage} alt=""/>
                    <div className="text-black text-lg lg:text-3xl font-black">
                        {name}
                    </div>
                </div>
                <div className="text-black text-xs font-semibold mt-5">
                    {desc}
                </div>
                <ul className="list text-black font-semibold mt-5">
                    {list.map(l => <li>{l}</li>)}
                </ul>
                <button
                    className="w-52 h-12 px-5 py-1.5 bg-yellow-400 rounded-3xl justify-start items-center gap-2.5 inline-flex mt-5"
                    onClick={() => dispatch(openModal({item: name, type: "item"}))}
                >
                    <div className="w-9 h-9 relative">
                        <img className="w-9 h-9 left-0 top-0 absolute" src={CartIcon}/>
                    </div>
                    <div className="w-32 text-black text-2xl font-semibold font-['Montserrat']">Заказать</div>
                </button>
            </div>
            <div className="hidden lg:flex flex-col w-2/4 p-5">
                <img src={images[0]} alt="" className="w-full"/>
                <div className="flex gap-5 mt-5">
                    {images.map((image, index) => index > 0 ? <img src={image} alt="" className="w-1/3"/> : null)}
                </div>
            </div>
            <div className="flex lg:hidden flex-col">
                <div className="text-black text-lg lg:text-3xl font-black">
                    {name}
                </div>
                <img src={mainImage} alt=""/>
                <div className="text-black text-xs font-semibold mt-5">
                    {desc}
                </div>
                <ul className="list text-black font-semibold text-sm mt-5">
                    {list.map(l => <li>{l}</li>)}
                </ul>
                <button
                    className="w-max px-5 py-1.5 bg-yellow-400 rounded-3xl justify-start items-center gap-2.5 inline-flex mt-5 m-auto"
                    onClick={() => dispatch(openModal({item: name, type: "item"}))}
                >
                    <div className="w-6 h-6 relative">
                        <img className="w-6 h-6 left-0 top-0 absolute" src={CartIcon}/>
                    </div>
                    <div className="text-black text-md font-semibold font-['Montserrat']">Заказать</div>
                </button>
            </div>
        </div>
    };

    return <div className="lg:p-10 mt-10 lg:mt-0 w-full">
        <div className="flex justify-between w-full px-10">
            <div className="w-4/6 text-black text-2xl lg:text-7xl font-bold font-['Montserrat']">
                Строительная техника BULL
            </div>
            <img src={BlackLogoImage} alt="" className="w-[70px] h-max"/>
        </div>
        <div className="w-full lg:w-5/6 text-black text-sm lg:text-3xl font-medium mt-5 px-10">
            ЭКСКАВАТОРЫ-ПОГРУЗЧИКИ МИРОВОГО КЛАССА,
            ОБЕСПЕЧИВАЮЩИЕ ВЫСОЧАЙШУЮ ЭФФЕКТИВНОСТЬ,КОМФОРТ ОПЕРАТОРА И БЕЗОПАСНОСТЬ.
        </div>
        <div className="flex justify-between items-center mt-10 lg:mt-5">
            <div className="w-[50px] lg:w-1/6 h-px border border-black"/>
            <div className="w-4/6 text-black text-lg lg:text-5xl font-semibold text-center">Каталог нашей продукции
            </div>
            <div className="w-[50px] lg:w-1/6 h-px border border-black"/>
        </div>
        <div className="w-full bg-gradient-to-t from-black to-white p-10 lg:p-20 flex flex-col gap-10">
            {items.map(item => <Item {...item}/>)}
        </div>
    </div>
};

export default BigCatalog;