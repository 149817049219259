import React from "react";
import Header from "./components/UI/Header";
import FirstBlock from "./components/UI/FirstBlock";
import Advantages from "./components/UI/Advantages";
import About from "./components/UI/About";
import Map from "./components/UI/Map";
import FormPrice from "./components/UI/FormPrice";
import IndividualBlock from "./components/UI/IndividualBlock";
import Service from "./components/UI/Service";
import Question from "./components/UI/Question";
import Footer from "./components/UI/Footer";
import Catalog from "./components/UI/Catalog";
import Modal from "./components/UI/Modal";
import ModalForm from "./components/UI/ModalForm";

const App = () => {
    return <div className="App">
        <Header/>
        <div className="h-20"></div>
        <FirstBlock/>
        <Advantages/>
        <About/>
        <Map/>
        <FormPrice/>
        <Catalog/>
        <IndividualBlock/>
        <Service/>
        <Question/>
        <Footer/>
        <Modal/>
        <ModalForm/>
    </div>
}

export default App;
