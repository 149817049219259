import React, {useRef, useState} from "react";
import Slider from "react-slick";
import LeftBigArrow from "../../img/icons/leftBig.png";
import RightBigArrow from "../../img/icons/rightBig.png";
import CartIcon from "../../img/icons/cart.png";
import CartWhiteIcon from "../../img/icons/cartWhite.png";
import {openModal} from "../../store/appSlice";

import {db} from "../../db";

import SL220_4in1_Item from "../../img/items/SL220_4in1/main.png";
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";

const Catalog = () => {
    console.log(db.items.map(() => console.log("e")));
    let slider = useRef();
    let sliderMobile = useRef();
    const [items, setItems] = useState(db.items);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const Item = ({name, desc, list, mainImage}) => {
        return <div className="catalog-item">
            <p className="title text-2xl font-black slide-center:text-yellow-400">
                {name}
            </p>
            <img src={mainImage} alt=""/>
            <p className="text">
                {desc}
            </p>
            <ul className="list">
                {list.map(l => <li>{l}</li>)}
            </ul>
            <button className="btn btn-yellow" onClick={() => dispatch(openModal({item: name, type: "item"}))}>
                <img src={CartIcon} alt="" className="cart-black w-5 mr-1"/>
                <img src={CartWhiteIcon} alt="" className="cart-white w-5 mr-1"/>
                Заказать
            </button>
        </div>
    };

    const sliderSettings = {
        className: "center",
        centerMode: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        speed: 500,
        dots: false,
    };

    const sliderMobileSettings = {
        className: "center",
        centerMode: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        dots: false,
    };

    return <div className="catalog" id="catalog">
        <p className="text-amber-500 text-3xl lg:text-6xl font-bold mb-5">КАТАЛОГ</p>
        <p className="text-black text-sm lg:text-2xl font-bold lg:w-[80%]">Техника под брендом BULL появилась в 1993 году. Новый бренд был создан под конкретные
            запросы российских клиентов и вобрал в себя лучшие технические решения завода и тот опыт, который наработала
            компания «Большой-Техника» в нашей стране</p>
        <div className="desktop">
            <Slider {...sliderSettings} ref={slide => (slider = slide)}>
                {items.map(item => <Item {...item}/>)}
            </Slider>
        </div>
        <div className="hidden lg:flex justify-between items-center mt-5">
            <div>
                <button className="btn btn-yellow opacity-0">Перейти в каталог</button>
            </div>
            <div className="flex justify-between w-1/4">
                <button onClick={() => slider.slickPrev()}><img src={LeftBigArrow} alt=""/></button>
                <button onClick={() => slider.slickNext()}><img src={RightBigArrow} alt=""/></button>
            </div>
            <div>
                <button className="px-2 py-2 bg-yellow-400 rounded-3xl justify-center items-center gap-5 inline-flex font-bold" onClick={() => navigate("/catalog")}>Перейти в каталог</button>
            </div>
        </div>
        <div className="buttons mobile -mb-10">
            <div>
                <button className="btn btn-black" onClick={() => navigate("/catalog")}>Перейти в каталог</button>
            </div>
            <div className="arrows">
                <button onClick={() => sliderMobile.slickPrev()}><img src={LeftBigArrow} alt=""/></button>
                <button onClick={() => sliderMobile.slickNext()}><img src={RightBigArrow} alt=""/></button>
            </div>
        </div>
        <div className="mobile">
            <Slider {...sliderMobileSettings} ref={slide => (sliderMobile = slide)}>
                {items.map(item => <Item {...item}/>)}
            </Slider>
        </div>
    </div>
};

export default Catalog;