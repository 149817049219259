import React from "react";
import {useDispatch} from "react-redux";
import {openModal} from "../../store/appSlice";

const IndividualBlock = () => {
    const dispatch = useDispatch();

    return <div className="individual-block">
        <div className="individual-block-text">
            <p className="title">Индивидуальный подход</p>
            <p className="subtitle">Наши специалисты свяжутся с вами и найдут оптимальные для вас условия сотрудничества</p>
        </div>
        <div className="individual-block-buttons">
            <button className="btn btn-yellow btn-big effects-white-shadow" onClick={() => dispatch(openModal({item: "Индивидуальный подход", type: ""}))}>
                Обратная связь
            </button>
        </div>
    </div>
};

export default IndividualBlock;