import React, {useEffect, useState} from "react";
import CartWhiteIcon from "../../img/icons/cartWhite.png";
import {ReactSVG} from "react-svg";
import CloseIcon from "../../img/icons/close.svg";
import {useDispatch, useSelector} from "react-redux";
import {closeModal} from "../../store/appSlice";

const ModalForm = () => {
    const isShow = useSelector(state => state.app.modalOpen);
    const nameItem = useSelector(state => state.app.modalItem);
    const type = useSelector(state => state.app.modalType);
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [comment, setComment] = useState("");
    const [allowPolicy, setAllowPolicy] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const send = e => {
        e.preventDefault();
        setIsLoading(true);

        fetch(`https://gkbig.bitrix24.ru/rest/290/jsg2ep5s0uat2e3s/crm.deal.add.json?FIELDS[TITLE]=${encodeURI(`Заявка с сайта BULL - "${nameItem}" - ${phone}`)}&FIELDS[COMMENTS]=${encodeURI(`Имя: ${name}; Телефон: ${phone}${comment ? `; Вопрос/Комментарий: ${comment}` : ""}`)}`).then(() => {
            setIsLoading(false);
            dispatch(closeModal());
            setName("");
            setPhone("");
            setComment("");
            setAllowPolicy(false);
        });
    };

    return <>
        <div
            className={`w-screen h-screen fixed bg-opacity-50 bg-black z-50 top-0 left-0 ${isShow ? "flex" : "hidden"} justify-center items-center`}>
            <div className="fixed top-10 right-10" onClick={() => dispatch(closeModal())}>
                <ReactSVG src={CloseIcon}/>
            </div>
            <div className="form-price-form">
                <form onSubmit={send}>
                    <input type="text" placeholder="Имя" onInput={(e) => setName(e.target.value)} value={name}/>
                    <input type="text" placeholder="Телефон" onInput={(e) => setPhone(e.target.value)} value={phone}/>
                    {
                        type === "item"
                            ? <input type="text" placeholder="Модель погрузчика" value={nameItem} disabled/>
                            : null
                    }
                    {
                        type === "q"
                            ? <input type="text" placeholder="Вопрос" onInput={e => setComment(e.target.value)} value={comment}/>
                            : null
                    }
                    <button disabled={isLoading || (!phone || !name || !allowPolicy)}>{isLoading ? "Отправка..." : "Отправить"}</button>
                    <label className="control control-checkbox">
                        Я даю согласие на обработку персональных данных и подтверждаю, что мне есть 18 лет
                        <input type="checkbox" checked={allowPolicy} onChange={() => setAllowPolicy(!allowPolicy)}/>
                        <div className="control_indicator"></div>
                    </label>
                </form>
            </div>
        </div>
    </>
};

export default ModalForm;