import {createSlice} from "@reduxjs/toolkit";

export const appSlice = createSlice({
    name: "app",
    initialState: {
        modalOpen: false,
        modalItem: "",
        modalType: "",
    },
    reducers: {
        openModal: (state, action) => {
            state.modalOpen = true;
            state.modalItem = action.payload.item;
            state.modalType = action.payload.type;
        },
        closeModal: state => {
            state.modalOpen = false;
            state.modalItem = "";
            state.modalType = "";
        },
    },
});

export const {
    openModal,
    closeModal,
} = appSlice.actions;

export default appSlice.reducer;