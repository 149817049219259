import React from "react";
import LogoImg from "../../img/Logo.png";
import {Link} from "react-router-dom";

const Footer = () => {
    const Title = ({children}) => <p className="text-xl font-black uppercase mb-5">{children}</p>;

    const MenuLink = ({children, to = "#", type = "react", target = ""}) => type === "react"
        ? <Link to={to} className="text-sm font-semibold">{children}</Link>
        : <a href={to} target={target} className="text-sm font-semibold">{children}</a>;

    return <footer className="bg-black flex flex-col lg:flex-row p-5 text-white" id="contacts">
        <div className="w-full lg:w-[35%]">
            <img src={LogoImg} alt="" className="mb-5 lg:w-[70%]"/>
            <p className="font-black text-2xl lg:font-bold lg:text-xl">ООО "Большой-Техника"</p>
            <p className="text-lg lg:mt-5">ОФИЦИАЛЬНЫЙ ДИЛЕР BULL НА ТЕРРИТОРИИ РОССИИ</p>
            <div className="flex lg:hidden gap-5 mt-2.5">
                <a href="tel:88006004136" className="footer-nav-item">
                    8(800) 600-41-36
                </a>
                <a href="mailto:info@big-teh.ru" className="footer-nav-item">
                    info@big-teh.ru
                </a>
            </div>
        </div>
        <div className="flex lg:hidden flex-row mt-5 gap-5">
            <div className="flex flex-col">
                <Title>Каталог</Title>
                <MenuLink>SL220 (4 в 1)</MenuLink>
                <MenuLink>SL220</MenuLink>
                <MenuLink>SL300</MenuLink>
                <MenuLink>3SX</MenuLink>
                <MenuLink>4SX</MenuLink>
                <MenuLink>SL1050</MenuLink>
                <MenuLink>SL700</MenuLink>
                <MenuLink>SLC1500</MenuLink>
                <MenuLink>EW210</MenuLink>
                <MenuLink>EW180</MenuLink>
            </div>
            <div className="flex flex-col">
                <Title>Быстрое меню</Title>
                <MenuLink type="react" to="/#first-block">Главная</MenuLink>
                <MenuLink type="react" to="/#about">О нас</MenuLink>
                <MenuLink type="react" to="/#catalog">Каталог</MenuLink>
                <MenuLink type="react" to="/#service">Сервис</MenuLink>
                <MenuLink type="react" to="/#contacts">Контакты</MenuLink>
            </div>
        </div>
        <div className="w-[calc(calc(100%-35%)/3)] hidden lg:flex flex-col">
            <Title>Каталог</Title>
            <MenuLink>SL220 (4 в 1)</MenuLink>
            <MenuLink>SL220</MenuLink>
            <MenuLink>SL300</MenuLink>
            <MenuLink>3SX</MenuLink>
            <MenuLink>4SX</MenuLink>
            <MenuLink>SL1050</MenuLink>
            <MenuLink>SL700</MenuLink>
            <MenuLink>SLC1500</MenuLink>
            <MenuLink>EW210</MenuLink>
            <MenuLink>EW180</MenuLink>
        </div>
        <div className="w-[calc(calc(100%-35%)/3)] hidden lg:flex flex-col">
            <Title>Быстрое меню</Title>
            <MenuLink type="react" to="/#first-block">Главная</MenuLink>
            <MenuLink type="react" to="/#about">О нас</MenuLink>
            <MenuLink type="react" to="/#catalog">Каталог</MenuLink>
            <MenuLink type="react" to="/#service">Сервис</MenuLink>
            <MenuLink type="react" to="/#contacts">Контакты</MenuLink>
        </div>
        <div className="w-[calc(calc(100%-35%)/3)] hidden lg:flex flex-col">
            <Title>Быстрое меню</Title>
            <MenuLink to="tel:88006004136">8(800) 600-41-36</MenuLink>
            <MenuLink to="mailto:info@big-teh.ru">info@big-teh.ru</MenuLink>
        </div>
    </footer>
};

export default Footer;