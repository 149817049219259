export const db = {
    items: [
        {
            name: "Фронтальный погрузчик Bull SL220(4в1)",
            desc: "Комфортабельная кабина, кондиционер, магнитола с USB, вентилятор, проблесковый маячок желтого цвета, электрический предпусковой подогреватель, ковш стандартный с зубьями, 4в1 челюстной ковш, джойстики",
            list: [
                "Объём ковша, м3 - 0.9 (ковш 4в1)",
                "Грузоподъёмность, кг - 2.200",
                "Мощность двигателя, л.с -95.17(70 кВт)",
            ],
            mainImage: require("./img/items/SL220_4in1/main.png"),
            images: [
                require("./img/items/SL220_4in1/0.png"),
                require("./img/items/SL220_4in1/1.png"),
                require("./img/items/SL220_4in1/2.png"),
                require("./img/items/SL220_4in1/3.png"),
            ],
        },
        {
            name: "Колесный экскаватор BULL EW210",
            desc: "Гидравлический насос Kawasaki, клапанный блок Parker, гидромотор привода поворота платформы Doosan, мосты ZF, дополнительная гидролиния под навесное оборудование, гидравлический быстросъёмный механизм, централизованная система смазки",
            list: [
                "Объём ковша, м3 - 1.0",
                "Грузоподъёмность, кг - 21.450",
                "Мощность двигателя, л.с -205 л.с.",
            ],
            mainImage: require("./img/items/EW210/main.png"),
            images: [
                require("./img/items/EW210/0.png"),
                require("./img/items/EW210/1.png"),
                require("./img/items/EW210/2.png"),
                require("./img/items/EW210/3.png"),
            ],
        },
        {
            name: "Колесный экскаватор BULL EW180",
            desc: "Гидравлический насос Kawasaki, клапанный блок Parker, гидромотор привода поворота платформы Doosan, мосты ZF, дополнительная гидролиния под навесное оборудование, гидравлический быстросъёмный механизм, централизованная система смазки",
            list: [
                "Объём ковша, м3 - 1.0",
                "Грузоподъёмность, кг - 17.100",
                "Мощность двигателя, л.с -205 л.с.",
            ],
            mainImage: require("./img/items/EW180/main.png"),
            images: [
                require("./img/items/EW180/0.png"),
                require("./img/items/EW180/1.png"),
                require("./img/items/EW180/2.png"),
                require("./img/items/EW180/3.png"),
            ],
        },
        {
            name: "Мини-погрузчик BULL SL1050",
            desc: "Мини-погрузчик SL1050 с функцией HighFlow (увеличение гидропотока до 140л/мин) и универсальной кареткой - делает данную модель идеальной для работы с любым навесным оборудованием и готова под абсолютно всевозможные задачи",
            list: [
                "Объём ковша, м3 - 0.55",
                "Грузоподъёмность, кг - 1.050",
                "Мощность двигателя, л.с -74.8 л.с.",
            ],
            mainImage: require("./img/items/SL1050/main.png"),
            images: [
                require("./img/items/SL1050/0.png"),
                require("./img/items/SL1050/1.png"),
                require("./img/items/SL1050/2.png"),
                require("./img/items/SL1050/3.png"),
            ],
        },
        {
            name: "Мини-погрузчик BULL SL700 (с кондиционером)",
            desc: "Высота по шарнирному пальцу - 3100 мм, ширина по ковшу -  1740 мм, ширина колеи -  1450 мм , колесная база - 991 мм, максимальный клиренс - 180 мм, угол выгрузки - 40 градусов,  высота выгрузки при открытом ковше - 2500 мм, дальность выгрузки - 710 мм",
            list: [
                "Объём ковша, м3 - 0.45",
                "Грузоподъёмность, кг - 700",
                "Мощность двигателя, л.с -49.9 л.с.",
            ],
            mainImage: require("./img/items/SL700/main.png"),
            images: [
                require("./img/items/SL700/0.png"),
                require("./img/items/SL700/1.png"),
                require("./img/items/SL700/2.png"),
                require("./img/items/SL700/3.png"),
            ],
        },
        {
            name: "Мини-погрузчик гусеничный BULL SLC1500",
            desc: "Гусеничный мини-погрузчик, в комплектации с кондиционером, ride control, high flow до 140 л/мин, управление - джойстики.Надежный, известный всем, двигатель Weichai WP4.",
            list: [
                "Объём ковша, м3 - 0.45",
                "Грузоподъёмность, кг - 700",
                "Мощность двигателя, л.с -49.9 л.с.",
            ],
            mainImage: require("./img/items/SLC1500/main.png"),
            images: [
                require("./img/items/SLC1500/0.png"),
                require("./img/items/SLC1500/1.png"),
                require("./img/items/SLC1500/2.png"),
                require("./img/items/SLC1500/3.png"),
            ],
        },
        {
            name: "Экскаватор-погрузчик BULL 3SX",
            desc: "Колеса 24 и 14 дюйма, крылья колес, кабина с защитой FOPS/ROPS, кондиционер и отопитель, телескопическая рукоять экскаваторной стрелы, светодиодный IP65 проблесковый маячок на магните, гидравлически смещаемая задняя каретка",
            list: [
                "Объём ковша, м3 - 1.0",
                "Грузоподъёмность, кг - 2.500",
                "Мощность двигателя, л.с -115 л.с.",
            ],
            mainImage: require("./img/items/3SX/main.png"),
            images: [
                require("./img/items/3SX/0.png"),
                require("./img/items/3SX/1.png"),
                require("./img/items/3SX/2.png"),
                require("./img/items/3SX/3.png"),
            ],
        },
        {
            name: "Экскаватор-погрузчик BULL 4SX",
            desc: "Колеса 28 дюймов, крабовый ход, КП Powershift, крылья колес, кабина с защитой FOPS/ROPS, кондиционер, отопитель салонный, телескопическая рукоять, проблесковый маячок, задняя гидравлическая смещаемая каретка, дополнительная линия для гидромолота",
            list: [
                "Объём ковша, м3 - 1.2",
                "Грузоподъёмность, кг - 2.500",
                "Мощность двигателя, л.с -115 л.с.",
            ],
            mainImage: require("./img/items/4SX/main.png"),
            images: [
                require("./img/items/4SX/0.png"),
                require("./img/items/4SX/1.png"),
                require("./img/items/4SX/2.png"),
                require("./img/items/4SX/3.png"),
            ],
        },
        {
            name: "Фронтальный погрузчик BULL SL300",
            desc: "Комфортабельная кабина, доп. гидролиния, кондиционер, магнитола с USB, камера заднего вида, вентилятор, проблесковый маячок желтого цвета, электрический предпусковой подогреватель 220B, ковш стандартный с зубьями, джойстики",
            list: [
                "Объём ковша, м3 - 1.5",
                "Грузоподъёмность, кг - 2.500",
                "Мощность двигателя, л.с -95.17(70 кВт)",
            ],
            mainImage: require("./img/items/SL300/main.png"),
            images: [
                require("./img/items/SL300/0.png"),
                require("./img/items/SL300/1.png"),
                require("./img/items/SL300/2.png"),
                require("./img/items/SL300/3.png"),
            ],
        },
        {
            name: "Фронтальный погрузчик одноковшовый Bull SL220",
            desc: "Комфортабельная кабина, кондиционер, магнитола с USB, вентилятор, проблесковый маячок желтого цвета, электрический предпусковой подогреватель, ковш стандартный с зубьями, джойстики",
            list: [
                "Объём ковша, м3 - 1.0",
                "Грузоподъёмность, кг - 2.200",
                "Мощность двигателя, л.с -95.17(70 кВт)",
            ],
            mainImage: require("./img/items/SL220/main.png"),
            images: [
                require("./img/items/SL220/0.png"),
                require("./img/items/SL220/1.png"),
                require("./img/items/SL220/2.png"),
                require("./img/items/SL220/3.png"),
            ],
        },
    ],
}