import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import scrollToElement from "scroll-to-element";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {createBrowserRouter, Outlet, useLocation} from "react-router-dom";
import {RouterProvider} from "react-router";
import Header from "./components/UI/Header";
import Footer from "./components/UI/Footer";
import BigCatalog from "./components/UI/BigCatalog";
import {Provider} from "react-redux";
import store from "./store";
import ModalForm from "./components/UI/ModalForm";

const OutletPage = () => {
    const location = useLocation();
    const [lastKey, setLastKey] = useState(location.key);

    useEffect(() => {
        if (lastKey !== location.key) {
            console.log(lastKey);
            setLastKey(location.key);
            if (location.hash) {
                scrollToElement(location.hash);
            }
        }
    }, [lastKey, location]);

    return <Outlet/>
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <OutletPage/>,
        children: [
            {
                path: "",
                element: <App/>,
            },
            {
                path: "catalog",
                element: <>
                    <Header/>
                    <div style={{height: "80px"}}/>
                    <BigCatalog/>
                    <ModalForm/>
                    <Footer/>
                </>,
            }
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router}/>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
