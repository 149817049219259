import React, {useState} from "react";
import MachineFormShadowImage from "../../img/MachineFormShadow.png";
import MachineFormImage from "../../img/MachineForm.png";
import {closeModal} from "../../store/appSlice";

const FormPrice = () => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [comment, setComment] = useState("");
    const [allowPolicy, setAllowPolicy] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const send = e => {
        e.preventDefault();
        setIsLoading(true);

        fetch(`https://gkbig.bitrix24.ru/rest/290/jsg2ep5s0uat2e3s/crm.deal.add.json?FIELDS[TITLE]=${encodeURI(`Заявка с сайта BULL - "Индивидуальное предложение Форма" - ${phone}`)}&FIELDS[COMMENTS]=${encodeURI(`Имя: ${name}; Телефон: ${phone}${comment ? `; Модель погрузчика: ${comment}` : ""}`)}`).then(() => {
            setIsLoading(false);
            setName("");
            setPhone("");
            setComment("");
            setAllowPolicy(false);
        });
    };

    return <div className="form-price">
        <div className="form-price-content">
            <p className="title">
                ПОЛУЧИТЬ ИНДИВИДУАЛЬНОЕ ПРЕДЛОЖЕНИЕ ПО САМОЙ НИЗКОЙ ЦЕНЕ
            </p>
            <img src={MachineFormShadowImage} alt=""/>
        </div>
        <div className="form-price-form" style={{zIndex: 10}}>
            <form onSubmit={send}>
                <input type="text" placeholder="Имя" onInput={e => setName(e.target.value)} value={name}/>
                <input type="text" placeholder="Телефон" onInput={e => setPhone(e.target.value)} value={phone}/>
                <input type="text" placeholder="Модель погрузчика" onInput={e => setComment(e.target.value)}
                       value={comment}/>
                <button
                    disabled={isLoading || (!phone || !name || !comment || !allowPolicy)}>{isLoading ? "Отправка..." : "Отправить"}</button>
                <label className="control control-checkbox">
                    Я даю согласие на обработку персональных данных и подтверждаю, что мне есть 18 лет
                    <input type="checkbox" checked={allowPolicy} onChange={() => setAllowPolicy(!allowPolicy)}/>
                    <div className="control_indicator"></div>
                </label>
            </form>
        </div>
        <div className="form-price-form-image">
            <img src={MachineFormImage} alt=""/>
        </div>
    </div>
};

export default FormPrice;